import { parseDate } from "chrono-node";
import { addDays, format, setHours, setMinutes } from "date-fns";
import { FieldValues, UnpackNestedValue } from "react-hook-form";
import { FormValidators } from "..";
import { EventColor, PrimaryCategory } from "../../../reclaim-api/EventMetaTypes";
import { Smurf } from "../../../reclaim-api/Projects";
import { Task } from "../../../reclaim-api/Tasks";
import { notNull, notUndefined } from "../../../types";
import { getFormattedDisplayDate, DATE_TIME_DISPLAY_FORMAT, durationStr, isDateNowOrEarlier } from "../../../utils/dates";
import { parseDuration } from "../rhf/DurationControl";

export type TaskFormValues = {
  title: string;
  eventColor: string | null;
  eventCategory: string | null;
  timeChunksRequired: string;
  _additionalChunksRequired: string | null;
  snoozeUntil: string;
  due: string;
  minChunkSize: string;
  maxChunkSize: string;
  notes: string;
  priority: Smurf | null;
  alwaysPrivate: boolean;
};

export const newTaskFormValues = (defaults: FieldValues): UnpackNestedValue<TaskFormValues> => ({
  title: "",
  eventColor: EventColor.Auto.key,
  eventCategory: PrimaryCategory.SoloWork.key,
  timeChunksRequired: "",
  _additionalChunksRequired: "",
  snoozeUntil: "",
  due: "",
  minChunkSize: "",
  maxChunkSize: "",
  notes: "",
  priority: Smurf.Default,
  alwaysPrivate: defaults.alwaysPrivate !== undefined ? defaults.alwaysPrivate : false,
});

export const defaultFormValues = (defaults?: FieldValues): UnpackNestedValue<TaskFormValues> => ({
  title: "",
  eventColor: EventColor.Auto.key,
  eventCategory: PrimaryCategory.SoloWork.key,
  timeChunksRequired: defaults?.timeChunksRequired || "1 hr",
  _additionalChunksRequired: "",
  snoozeUntil: "now",
  due: !!defaults?.due
    ? format(addDays(setMinutes(setHours(new Date(), 20), 0), 3), DATE_TIME_DISPLAY_FORMAT) || ""
    : "",
  minChunkSize: defaults?.minChunkSize || "1 hr",
  maxChunkSize: defaults?.maxChunkSize || "2 hr",
  notes: "",
  priority: Smurf.Default,
  alwaysPrivate: defaults?.alwaysPrivate !== undefined ? defaults.alwaysPrivate : false,
});

const parseDateField = (date: string) => parseDate(date, undefined);

export const dataToValues = (data: Partial<Task> | null, defaults?: FieldValues): FieldValues => {
  const defaultValues = defaultFormValues(defaults);

  if (!data) return newTaskFormValues(defaults || {});

  let values: FieldValues = {
    title: data.title,
    eventColor: data.eventColor?.key,
    eventCategory: data.eventCategory?.key,
    timeChunksRequired: durationStr((data.timeChunksRequired as number) * 15 * 60, true),
    minChunkSize: durationStr((data.minChunkSize as number) * 15 * 60, true),
    notes: data.notes,
    maxChunkSize: durationStr((data.maxChunkSize as number) * 15 * 60, true),
    priority: data.priority,
    alwaysPrivate: data.alwaysPrivate,
  };

  // We dont want to populate the form with a snooze in the past.
  if (!!data.snoozeUntil && isDateNowOrEarlier(data.snoozeUntil)) {
    values.snoozeUntil = defaultValues.snoozeUntil;
  } else {
    values.snoozeUntil = !!data.snoozeUntil ? getFormattedDisplayDate(data.snoozeUntil, DATE_TIME_DISPLAY_FORMAT) : undefined;
  }

  values.due = data.due ? getFormattedDisplayDate(data.due, DATE_TIME_DISPLAY_FORMAT) : "";

  values = Object.entries(values).reduce((acc, [k, v]) => {
    if (notUndefined(v) && notNull(v)) acc[k] = v;
    return acc;
  }, {});

  return { ...defaultValues, ...values };
};

export const valuesToData = (values: FieldValues, defaults?: FieldValues): Partial<Task> => {
  // First add the missing defaults:
  const defaulted: FieldValues = Object.entries(values).reduce((acc, [k, v]) => {
    if ((v === undefined || v === "") && defaults?.[k]) {
      acc[k] = defaults[k];
    } else {
      acc[k] = v;
    }
    return acc;
  }, {});

  // remove any empty fields
  const data: Partial<Task> = Object.entries(defaulted).reduce((acc, [k, v]) => {
    if (v !== undefined && v !== "") acc[k] = v;
    return acc;
  }, {});

  if (defaulted.eventColor) data.eventColor = EventColor.get(defaulted.eventColor);
  if (defaulted.eventCategory) data.eventCategory = PrimaryCategory.get(defaulted.eventCategory);
  if (defaulted.timeChunksRequired)
    data.timeChunksRequired = (parseDuration(defaulted.timeChunksRequired) as number) / 15;

  if (defaulted.snoozeUntil) data.snoozeUntil = parseDateField(defaulted.snoozeUntil);
  if (defaulted.due === "") data.due = null;
  else if (defaulted.due) data.due = parseDateField(defaulted.due);

  if (defaulted.minChunkSize) data.minChunkSize = (parseDuration(defaulted.minChunkSize) as number) / 15;
  if (defaulted.maxChunkSize) data.maxChunkSize = (parseDuration(defaulted.maxChunkSize) as number) / 15;

  return data;
};

export const TaskFormValidators: FormValidators = {
  dueDate: (value: string, snoozeUntil: string) => {
    const parsed = parseDateField(value);
    const snoozeParsed = parseDateField(snoozeUntil);
    return !!parsed && !!snoozeParsed && parsed.getTime() <= snoozeParsed.getTime()
      ? "must be after scheduled start"
      : true;
  },
  maximumMinChunkSize: (value: string, maxChunkSize: string) => {
    const parsed = parseDuration(value);
    const maxParsed = parseDuration(maxChunkSize);
    return !!parsed && !!maxParsed && parsed > maxParsed ? "cannot exceed maximum block duration" : true;
  },
  maximumChunkSize: (value: string, timeChunksRequired: string) => {
    const parsed = parseDuration(value);
    const maxParsed = parseDuration(timeChunksRequired);
    return !!parsed && !!maxParsed && parsed > maxParsed ? "cannot exceed total time" : true;
  },
};
