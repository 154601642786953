import { makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { VFC } from "react";
import { useTrialExpiration } from "../hooks/atoms/useTrialExpiration";
import { SubscriptionStatus } from "../reclaim-api/team/Team";
import { pluralize } from "../utils/strings";
import { Banner } from "./Banner";
import { Link } from "./Link";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
  }),
  {
    classNamePrefix: "TrialExpirationBanner",
  }
);

export type TrialExpirationBannerJSSClassKey = keyof ReturnType<typeof useStyles>;

export type TrialExpirationBannerProps = {
  classes?: Partial<ClassNameMap<TrialExpirationBannerJSSClassKey>>;
  className?: string;
};

export const TrialExpirationBanner: VFC<TrialExpirationBannerProps> = ({ className, classes: extClasses }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const { subscriptionStatus, daysLeftInTrial, upsell, pendingClawback } = useTrialExpiration();
  const showBanner =
    (!!daysLeftInTrial && daysLeftInTrial < 30 && subscriptionStatus !== SubscriptionStatus.Active) || pendingClawback;
  const showTrialEnding = !!daysLeftInTrial && daysLeftInTrial > 0;

  return (
    <Banner className={clsx(classes.root, className)} in={showBanner}>
      {showTrialEnding && (
        <>
          Just
          <strong>
            {daysLeftInTrial} {pluralize(daysLeftInTrial || 0, "day")} left
          </strong>
          with access to {upsell} -&nbsp;<Link href="/billing">Upgrade now!</Link>
        </>
      )}

      {!showTrialEnding && pendingClawback && (
        <>
          Your trial is over. Pro and Team features will be disabled soon -&nbsp;
          <Link href="/billing">Purchase now to avoid interruption!</Link>
        </>
      )}
    </Banner>
  );
};
